.section{

    &.section--grid{
        background-color: $color-black;
        border: 1px solid $color-black;
        margin: 0 auto;

        @include mq(sm){
            max-width: 50vw;
        }

        @include mq(lg){
            max-width: 33vw;
        }

        .grid-container{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            // grid-template-rows: 33.3333%;
            gap: 1vh;
            height: 100%;

            .cube{
                @include paragraph-sm;
                aspect-ratio: 1;
                background-color: $color-gray-dark;
                border: 1px solid black;
                color: $color-gray;
                height: 100%;
                position: relative;

                .face{
                    display: flex;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    display: none;
                    width: 100%;
                    z-index: 2;

                    &.face--1{
                        display: none;
                        padding: pxrem(10);
                        z-index: 1;
                    }

                    &.show{
                        display: flex;
                    }

                    picture{
                        background-color: $color-white;
                    }

                    .label{
                        display: none;
                    }
                }

                &.active{
                    color: $color-white;
                    cursor: pointer;

                    .face--1{
                        display: flex;
                    }
                }

                &.cube--1{

                    &.active{

                        .face--1{
                            background-color: $color-green;
                        }
                        
                    }
                }

                &.cube--2{

                    &.active{

                        .face--1{
                            background-color: $color-red;
                        }
                        
                    }
                }

                &.cube--3{

                    &.active{

                        .face--1{
                            background-color: $color-light-blue
                        }
                        ;
                    }
                }

                &.cube--4{

                    &.active{

                        .face--1{
                            background-color: $color-orange;
                        }
                        
                    }
                }

                &.cube--5{

                    &.active{

                        .face--1{
                            background-color: $color-brown;
                        }
                        
                    }
                }

                &.cube--6{

                    &.active{

                        .face--1{
                            background-color: $color-green;
                        }
                        
                    }
                }

                &.cube--7{

                    &.active{

                        .face--1{
                            background-color: $color-violet;
                        }
                        
                    }
                }

                &.cube--8{

                    &.active{

                        .face--1{
                            background-color: $color-red;
                        }
                        
                    }
                }

                &.cube--9{

                    &.active{

                        .face--1{
                            background-color: $color-light-blue
                        }
                        ;
                    }
                }
            }
        }
    }
}