// Typography styles

@mixin basicTypographyStyle() {
  margin: 0;
}

@mixin title-1($color: $color-white, $font-family: $font-primary, $font-weight: 400) {
  @include basicTypographyStyle;
  font-size: $font-xlarge;
  color: $color;
  font-weight: $font-weight;
  font-family: $font-family;
}

@mixin title-2($color: $color-white, $font-family: $font-primary, $font-weight: 400) {
  @include basicTypographyStyle;
  font-size: $font-large;
  color: $color;
  font-weight: $font-weight;
  font-family: $font-family;
}
@mixin title-3($color: $color-white, $font-family: $font-primary, $font-weight: 400) {
  @include basicTypographyStyle;
  font-size: $font-discrete;
  color: $color;
  font-weight: $font-weight;
  font-family: $font-family;
}

@mixin paragraph-lg($color: $color-white, $font-family: $font-primary, $font-weight: 400) {
  @include basicTypographyStyle;
  font-size: $font-medium;
  color: $color;
  font-weight: $font-weight;
  font-family: $font-family;
}

@mixin paragraph($color: $color-white, $font-family: $font-primary, $font-weight: 400) {
  @include basicTypographyStyle;
  font-size: $font-normal;
  color: $color;
  font-weight: $font-weight;
  font-family: $font-family;
}

@mixin paragraph-sm($color: $color-white, $font-family: $font-primary, $font-weight: 400) {
  @include basicTypographyStyle;
  font-size: $font-small;
  color: $color;
  font-weight: $font-weight;
  font-family: $font-family;
}