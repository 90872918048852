.app-container {
    background-color: $color-black;
    height: 100vh;
    padding: pxrem(27);
    width: 100vw;

    .login-container,
    .download-container{
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;

        .excel-button{
            @include paragraph($color-black);
            background-color: $color-white;
            border-radius: pxrem(20);
            border: 1px solid $color-black;
            padding: pxrem(10);
        }
    }

    .login-container{

        .form-container{
            background-color: $color-white;
            display: flex;
            flex-flow: column;
            padding: pxrem(30);

            label{
                @include paragraph-sm($color-black);
            }

            input{
                @include paragraph($color-black);
                border: 0;
                border-bottom: 1px solid $color-black;
                margin-bottom: pxrem(10);
            }

            .login-submit{
                @include paragraph($color-black);
                border: 1px solid $color-black;
                border-radius: pxrem(5);
                cursor: pointer;
                margin: 0 auto;
                padding: pxrem(3);
                width: fit-content;
            }
        }
    }

    &.no-interaction{
        pointer-events: none;
    }

    // background-color: ;
    .page {
        background-color: $color-black;
        left: 0;
        margin-top: 16.6666vh;
        min-height: 83.3333vh;
        padding: pxrem(27);
        position: absolute;
        top: 0;
        width: 100%;

        &.page--1 {
            display: block;
            opacity: 1;
            transition: opacity 0.6s ease-in-out;
            z-index: 2;

            .fase{
                @include paragraph-lg($color-gray);
                display: none;
                margin-top: pxrem(45);

                @include mq(md){
                    text-align: center;
                    width: 50%;
                    margin: pxrem(45) auto 0;
                }

                .text-1{
                    margin-bottom: pxrem(55);
                }

                &.fase--1{
                    display: block;
                }

                &.fase--3{

                    .input-container{
                        margin: 0 auto;
                        position: relative;
                        width: 70%;

                        &:after{
                            content: '@';
                            position: absolute;
                            height: 100%;
                            top: 30%;
                            left: 0;
                        }

                        input{
                            background: transparent;
                            border: 0;
                            border-bottom: 1px solid $color-white;
                            color: $color-white;
                            margin-bottom: pxrem(15);
                            margin-top: pxrem(15);
                            padding: pxrem(10) pxrem(5) pxrem(10) pxrem(30);
                            width: 100%;
                        }
                    }

                    .text-1{
                        text-align: left;
                    }
                }
            }
        }

        &.page--2 {
            opacity: 0;
            transition: opacity 0.6s ease-in-out;
            z-index: 1;
        }

        &.page--3{
            opacity: 0;
            z-index: 1;
        }
    }


    &.carousel-view {

        .page {

            &.page--1{
                // display: none;
                opacity: 0;
                z-index: 1;
            }

            &.page--2 {
                opacity: 1;
                z-index: 2;
            }

            &.page--3 {
                opacity: 0;
                z-index: 1;
            }
        }
    }

    &.credits{

        .page{

            &.page--1{
                opacity: 0;
                z-index: 1;
            }

            &.page--2{
                opacity: 0;
                z-index: 1;
            }

            &.page--3{
                opacity: 1;
                z-index: 2;
            }
        }
    }

}