.icon {
	display: block;
	flex-shrink: 0;
	pointer-events: none;

	// Icon transformations
	&.icon--rotate-45 {
		transform: rotate(45deg);
	}

	&.icon--rotate-90 {
		transform: rotate(90deg);
	}

	&.icon--rotate-180 {
		transform: rotate(180deg);
	}

	&.icon--rotate-270 {
		transform: rotate(270deg);
	}

	// Icon colors
	&.icon--white-fill {
		@include icon-fill($color-white);
	}

	&.icon--white-stroke {
		@include icon-stroke($color-white);
	}

	// Custom icons
	&.icon--name {
		height: pxrem(44);
		width: pxrem(120);

		@include mq(md) {
			height: pxrem(60);
			width: pxrem(160);
		}
	}

	&.icon--logo{
		height: pxrem(20);
		margin-top: pxrem(10);
		width: pxrem(65);
	}

	&.icon--logo-md{
		height: pxrem(45);
		margin-top: pxrem(10);
		width: pxrem(115);
	}

	&.icon--logo-bg{
		height: pxrem(40);
		margin-top: pxrem(20);
		width: pxrem(130);
	}
}