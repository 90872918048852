header{
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: pxrem(27);
    width: 100%;

    .title{
        @include title-3;
    }

    .hamburger{
        width: pxrem(21);
        height: pxrem(16);
        position: relative;
        z-index: 4;

        .line{
            width: 100%;
            height: pxrem(3);
            position: absolute;
            left: 0;
            background-color: $color-white;

            &.line--1{
                top: 0;
            }

            &.line--2{
                bottom: 0;
            }

            &.line--3{
                top: pxrem(6.5);
            }
        }

        &.open{

            .line{
                transition: all 0.3s ease-in;
    
                &.line--1{
                    top: pxrem(6.5);
                    transform: rotate(45deg);
                }
    
                &.line--2{
                    top: pxrem(6.5);
                    bottom: unset;
                    transform: rotate(-45deg);
                }
    
                &.line--3{
                    display: none;
                }
            }

        }
    }

    .menu{
        background-color: $color-black;
        transform: translateX(-100vw);
        transition: transform 0.3s ease-in;
        height: 100vh;
        left: 0;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: 3;

        &.open{
            display: block;
            transform: translateX(0);
        }

        .menu-container{
            margin-top: pxrem(120);
            padding: 0 pxrem(27);

            .menu-item{
                @include title-2;
                margin-bottom: pxrem(35);
            }
        }
    }
}