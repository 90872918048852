.section{

    &.section--loader{
        position: absolute;
        background-color: $color-black;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        opacity: 1;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-flow: column;
        z-index: 4;
    }

    &.fade{
        opacity: 0;
        transition: opacity 1s ease-in;
        // z-index: 1;
    }

    .container{
        @include title-1($color-white, $font-primary, 700)
    }
}