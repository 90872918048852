.button-container{
    display: flex;
    justify-content: center;

    .button{
        @include paragraph($color-black, $font-primary, 600);
        background-color: $color-light-yellow;
        border-radius: pxrem(10);
        cursor: pointer;
        display: none;
        margin-top: pxrem(45);
        padding: pxrem(5) 0;
        text-align: center;
        width: 40vw;

        @include mq(md){
            display: block;
            margin-top: 0;
            padding: pxrem(5) pxrem(10);
            width: fit-content;
        }
    }

    .share-button{
        @include paragraph($color-black, $font-primary, 600);
        background-color: $color-light-yellow;
        border-radius: pxrem(10);
        cursor: pointer;
        margin-bottom: pxrem(15);
        padding: pxrem(5) 0;
        text-align: center;
        width: 100%;

        @include mq(md){
            padding: pxrem(5) pxrem(10);
            width: fit-content;
        }
    }

    .save-button{
        @include paragraph($color-black, $font-primary, 600);
        background-color: $color-gray;
        border-radius: pxrem(10);
        cursor: pointer;
        padding: pxrem(5) 0;
        text-align: center;
        width: 100%;

        @include mq(md){
            padding: pxrem(5) pxrem(10);
            width: fit-content;
        }
    }

    .back-button,
    .reset-button{
        @include paragraph-sm($color-gray, $font-primary, 400);
        cursor: pointer;
        margin-top: pxrem(25);
        text-align: center;
        text-decoration: underline;
        width: 40vw;

        @include mq(md){
            padding: pxrem(5) pxrem(10);
            width: fit-content;
        }
    }
}