// =============================================================================
// Sizes
// =============================================================================

// Max widths and fixed components heights
$size-maxwidth:         1300px;

$header-height:         50px;
$header-height-small:   35px;

// =============================================================================
// Colors
// =============================================================================

// Brand colors
$color-green:           #009351;
$color-yellow:          #F3911B;
$color-light-yellow:    #FFD700;
$color-light-blue:      #2BABE2;
$color-violet:          #4953A0;
$color-brown:           #A33F17;
$color-red:             #E73243;
$color-orange:          #F3911B;

// Neutral colors
$color-white:           #FFFFFF;
$color-gray-light:      #F9F9F9;
$color-gray:            #C6C6C6;
$color-gray-dark:       rgba(217, 217, 217, 0.20);
$color-black:           #000000;


// =============================================================================
// Typography
// =============================================================================

$font-primary: "aktiv-grotesk-extended", sans-serif;
$font-secondary:  Georgia, Times, 'Times New Roman', serif;

$font-small:        pxrem(12);
$font-normal:       pxrem(16);
$font-discrete:     pxrem(20);
$font-medium:       pxrem(24);
$font-large:        pxrem(30);
$font-xlarge:       pxrem(40);


// =============================================================================
// Transitions
// =============================================================================

$transition-default: 250ms ease-in-out;