* {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: 0;
}

html {
  background-color: $color-black;
  min-height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
}

body {
  @include font-smoothing;
  background-color: $color-white;
  color: $color-gray;
  -webkit-text-size-adjust: 100%;
  font-family: $font-primary;
  font-size: $font-normal;
  line-height: 1.25;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

[unselectable] {
  -webkit-touch-callout: none;
  user-select: none;
}

a {
  color: $color-white;
  opacity: 1;
  transition: all $transition-default;
  // text-decoration: none;

  &:hover {
    opacity: .75;
    // text-decoration: none;
  }

  &:visited,
  &:active {
    color: $color-white;
  }

  &.no-underline{
    text-decoration: none;
  }
}

img,
media,
svg {
  display: block;
  height: auto;
  max-width: 100%;

  &.svg-symbols {
    display: none;
  }
}

ul,
ol {
  margin: 0;
}

/* Chrome autocomplete form fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

canvas{
  display: none;
  border: 1vh solid $color-black;
}

