.section{

    &.section--credits{
        min-height: 100vh;

        .credit-container{
            margin-bottom: pxrem(60);

            &:last-child{
                margin-bottom: 0;
            }
        }

        .credit-text{
            @include title-3;
            margin-bottom: pxrem(20);
        }
        
    }
}