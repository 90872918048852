// .carousel-view{

      .swiper-3d{
        perspective: 3000;
      }
    
      .swiper-slide {
        align-items: center;
        aspect-ratio: 1;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-wrap: wrap;
        padding: pxrem(30) pxrem(45);

        @include mq(md){
            display: block;
            flex-flow: column;
            padding: pxrem(60) pxrem(30);
        }

        .label{
            @include paragraph;
            display: none;
            padding-top: pxrem(25);
            text-align: center;
        }

        picture{
            border: 4px solid $color-black;
        }

        img{
            background-color: $color-white;
            height: pxrem(150);
            object-fit: cover;
        }
    
        .slider-text{
            @include paragraph-lg;
            text-align: center;
            padding: pxrem(20) 0;
            display: none;
        }
    
        &.swiper-slide-active{
            align-items: center;
            display: flex;
            justify-content: center;

            img{
                height: 100%;
                // padding: 0;
                // width: 80%;
            }
            
            .label{
                display: block;
            }
        }

        &.swiper-slide-prev,
        &.swiper-slide-next{
            
            img{
                height: pxrem(200)
            }
        }
      }
    
      .swiper-slide img {
        display: block;
        width: 100%;
      }
// }

.swiper {
    aspect-ratio: 1;
    min-height: 45vh;
    // max-width: pxrem(325);
    padding-bottom: pxrem(50);
    perspective-origin: center;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    width: 100%;
    // transform-origin: 0;

    @include mq(md){
        height: pxrem(440);
        padding-bottom: 0;
        width: pxrem(325);
    }

    &.effect{
        transform: scale(1.1)
    }
  }

  .navigation{
    bottom: 20px;
    display: none;
    flex-flow: row-reverse;
    flex-wrap: wrap;
    height: pxrem(40);
    justify-content: space-between;
    left: 25%;
    margin: 0 auto;
    position: absolute;
    top: calc(50% - pxrem(40));
    width: 50%;
    z-index: 10;

    @include mq(md){
        align-self: center;
        display: flex;
    }

    .swiper-button-next,
    .swiper-button-prev{
        bottom: 130%;
        color: $color-white;
        height: 100%;
        margin-top: 0;
        top: unset;
        width: unset;
    }
}

  .question{
    @include title-3;
    margin-bottom: pxrem(60);

    @include mq(md){
        text-align: center;
        margin-bottom: 0;
    }
  }

  .carousel-view{

    .swiper-slide{
        

        &.first-loading{
            // opacity: 0;

            &.swiper-slide-active{
                // opacity: 1;
            }
        }

        &.slide-fade{
            // transition: opacity 0.3s ease-in-out !important;
        }
    }
  }