.h-hide {
  @include hidden;
}

.h-visually-hidden {
  @include visually-hidden;
}

.h-noscroll {
  overflow: hidden;
}